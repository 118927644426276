<template>
    <div v-if="screenWidth <= 800 ? true : false " class="affix-container">
        <el-affix target=".affix-container" :offset="60">
            <el-input v-model="input1" @input="querySearchAsync" clearable placeholder="搜索商品" style="width: 50%"
                size="small" prefix-icon="Search" />
        </el-affix>
        <ul v-infinite-scroll="load" :style="'height:'+ (innerHeight -125) + 'px'" class="infinite-list"
            :infinite-scroll-disabled="disabled">
            <li v-for="(item, index) in dialogdata" :key="index" class="infinite-list-item">
                <el-descriptions direction="vertical" :column="6" border size="small">
                    <el-descriptions-item label="商品编码"
                        :span="3">{{item['combination_product_id']}}</el-descriptions-item>
                    <el-descriptions-item label="商品名称" :span="3">{{item['combined_name']}}</el-descriptions-item>
                    <el-descriptions-item label="品牌">{{item['brand']}}</el-descriptions-item>
                    <el-descriptions-item label="销售状态">{{item['sales_status']}}</el-descriptions-item>
                    <el-descriptions-item label="线上日常价">{{item['front_end_price']}}</el-descriptions-item>
                    <el-descriptions-item label="折扣率">{{item['discount']}}</el-descriptions-item>
                    <el-descriptions-item label="私域售价">{{item['private_sphere_price']}}</el-descriptions-item>
                    <el-descriptions-item label="库存">{{item['inventory']}}</el-descriptions-item>
                </el-descriptions>
            </li>
            <p v-if="!autocomplete">加载中...</p>
            <p v-if="disabled">我们是有底线的...</p>
        </ul>
    </div>
    <el-card class="box-card-b" v-else>
        <el-row :gutter="24">
            <el-col :span="6">
                <el-scrollbar :height="innerHeight <= 400 ? '400px' : innerHeight - 190 + 'px' ">
                    <el-space direction="vertical" alignment="flex-start">
                        <el-popover placement="bottom-end" trigger="click" width="300">
                            <template #reference>
                                <el-input v-model="input1" @input="querySearchAsync" clearable placeholder="搜索商品"
                                    style="width: 30%" prefix-icon="Search">
                                </el-input>
                            </template>
                            <div v-if="dialogdata[0]">
                                <el-scrollbar height="400px">
                                    <el-card style="margin-top: 2%;" shadow="hover" v-for="item,k in dialogdata"
                                        :key="k" @click="handleSelect(item)">
                                        <b>{{ item.combined_name }}</b>
                                        <p>{{ item.combination_product_id }}</p>
                                    </el-card>
                                </el-scrollbar>
                                <div class="my-header">
                                    <el-pagination background :page-size="15" hide-on-single-page layout="prev,  next "
                                        :total="total" @current-change="handleCurrentChange" prev-text='上一页'
                                        next-text='下一页' small />
                                    <el-button @click="dialogVisible = true" type="primary" size="small">
                                        查看更多
                                    </el-button>
                                </div>
                            </div>
                            <div v-else>无查询结果</div>
                        </el-popover>
                        <el-tag closable @close="handleClose(k)" v-for="v,k in tags" :key="k" size="large">
                            <div>{{v.combination_product_id}}</div>
                            <div>{{v.combined_name}}</div>
                        </el-tag>
                        <div v-if="tags.length > 0">
                            <el-button type="warning" plain @click="getproduct(1)">清空选择</el-button>
                            <el-button type="success" plain @click="getproduct()">获取价格表</el-button>
                        </div>
                    </el-space>
                </el-scrollbar>
            </el-col>
            <el-col :span="18">
                <el-main>
                    <el-table :data="product" @selection-change="handleSelectionChange"
                        :height="innerHeight <= 400 ? 400 : innerHeight - 190">
                        <el-table-column type="selection" width="55" />
                        <el-table-column label="编码" property="combination_product_id"></el-table-column>
                        <el-table-column label="名字" property="combined_name"></el-table-column>
                        <el-table-column label="数量" property="product_num">
                            <template #default="scope">
                                <el-input-number v-model="scope.row.product_num" :min="1" :max="100" size="small"
                                    @change="handleSelectionChange(selectionLis,scope.row)" />
                            </template>
                        </el-table-column>
                        <el-table-column label="线上日常价" property="front_end_price"></el-table-column>
                        <el-table-column label="折扣率" property="discount"></el-table-column>
                        <el-table-column label="私域售价" property="private_sphere_price"></el-table-column>
                        <el-table-column label="销售状态" property="sales_status"></el-table-column>
                        <el-table-column label="库存" property="inventory"></el-table-column>
                    </el-table>
                    <el-footer>
                        <el-descriptions>
                            <el-descriptions-item label="选中行数" label-align="right"
                                align="center">{{descriptions_item.row}}</el-descriptions-item>
                            <el-descriptions-item label="前端总价" label-align="right"
                                align="center">{{descriptions_item.one_p}}</el-descriptions-item>
                            <el-descriptions-item label="后端总价" label-align="right"
                                align="center">{{descriptions_item.tow_p}}</el-descriptions-item>
                        </el-descriptions>
                    </el-footer>
                </el-main>
            </el-col>
        </el-row>
    </el-card>
    <el-dialog v-model="dialogVisible" title="批量选择商品" :before-close="handleClosed" width="75%" :show-close="false">
        <template #header="{ close, titleId, titleClass }">
            <div class="my-header">
                <el-input v-model="input1" @input="querySearchAsync" clearable placeholder="搜索商品" style="width: 20%"
                    size="small" prefix-icon="Search" />
                <div>
                    <el-button @click="handleExport" type="warning">
                        <el-icon class="el-icon--left">
                            <Document />
                        </el-icon>
                        导出
                    </el-button>
                    <el-button @click="handleClosed" type="success">
                        <el-icon class="el-icon--left">
                            <CircleCheck />
                        </el-icon>
                        确认
                    </el-button>
                </div>
            </div>
        </template>
        <el-table :data="dialogdata" @selection-change="dialoghandleSelect"
            :height="innerHeight <= 400 ? 400 : innerHeight - 400">
            <el-table-column type="selection" width="55" />
            <el-table-column label="名字" property="combined_name"></el-table-column>
            <el-table-column label="编码" property="combination_product_id"></el-table-column>
            <el-table-column label="品牌" property="brand"></el-table-column>
            <el-table-column label="销售状态" property="sales_status"></el-table-column>
            <el-table-column label="线上日常价" property="front_end_price"></el-table-column>
            <el-table-column label="折扣率" property="discount"></el-table-column>
            <el-table-column label="私域售价" property="private_sphere_price"></el-table-column>
            <el-table-column label="库存" property="inventory"></el-table-column>
        </el-table>
        <el-pagination background :page-size="15" hide-on-single-page layout="prev, pager, next " :total="total"
            @current-change="handleCurrentChange" />
    </el-dialog>
</template>

<script>
    import jsonToExcel from '@/utils/jsonToExcel';
    import axios from "@/utils/request";
    // 订单助手页面
    import {
        mapState
    } from 'vuex'
    export default {
        name: 'NotesAssistant',
        computed: {
            // Vue计算属性
            ...mapState(['innerHeight', 'screenWidth']),
        },
        created() {
            //获取侧边列表
            axios({
                method: "post",
                url: 'getdata',
                data: {
                    tabname: '获取频词',
                    search: {},
                    where: []
                },
            }).then(res => {
                this.speech = res
            })
        },
        data() {
            return {
                dialogVisible: false,
                dialogdata: [],
                searchlist: {},
                visible: false,
                autocomplete: true,
                input1: '',
                descriptions_item: {
                    row: 0,
                    one_p: 0,
                    tow_p: 0,
                },
                value: '商品简称',
                cods: [],
                tags: [],
                product: [],
                selectionLis: [],
                total: 0,
                count: 10,
                currentPage: 1,
                disabled: false, //是否禁用刷新
                query_term: {},
                speech: [],
            }
        },
        methods: {
            load() {
                this.count += 2
                if (!this.query_term.tabname) {
                    return
                }
                if (this.autocomplete) {
                    this.autocomplete = false
                    this.query_term['tabname'] = '订单助手'
                    this.currentPage += 1
                    this.query_term['currentPage'] = this.currentPage
                    axios({
                        method: "post",
                        url: 'getdata',
                        data: this.query_term,
                    }).then(res => {
                        // this.dialogdata = res.data
                        this.total = res.total
                        if (res.last_page == this.currentPage) {
                            this.disabled = true
                        }
                        Array.prototype.push.apply(this.dialogdata, res.data);
                        this.autocomplete = true
                    })
                }
            },
            handleSelectionChange(val, ls = {
                combination_product_id: ''
            }) {
                let descriptions = {
                    'one_p': 0,
                    'row': val.length,
                    'tow_p': 0
                }
                if (val.length != 0) {
                    for (let i = 0; i < val.length; i++) {
                        if (val[i].combination_product_id == ls.combination_product_id) {
                            val[i] = ls
                        }
                        descriptions.one_p = parseFloat(((val[i].product_num * val[i].front_end_price) +
                            descriptions.one_p).toFixed(2))
                        descriptions.tow_p = parseFloat(((val[i].product_num * val[i].private_sphere_price) +
                            descriptions.tow_p).toFixed(2))
                    }
                }
                this.selectionLis = val
                this.descriptions_item = descriptions
            },
            handleSelect(item) {
                let resid = item.combination_product_id.split('+')
                let respr = item.combined_name.split('+')
                for (let i = 0; i < resid.length; i++) {
                    let itemid = resid[i].split('*')
                    let itempr = respr[i].split('*')
                    if (this.cods.indexOf(itemid[0]) !== -1) {
                        continue
                    }
                    this.tags.push({
                        'combination_product_id': itemid[0],
                        'combined_name': itempr[0],
                    })
                    this.cods.push(itemid[0])
                }
                if (this.screenWidth >= 800) {
                    this.getproduct()
                }
            },
            handleClose(s) {
                this.tags.splice(s, 1)
                this.cods.splice(s, 1)
                if (this.screenWidth >= 800) {
                    this.getproduct()
                }
            },
            handleExport() {
                if (!this.query_term.tabname) {
                    return
                }
                if (this.autocomplete) {
                    this.autocomplete = false
                    this.query_term['tabname'] = '导出产品定价表'
                    axios({
                        method: "post",
                        url: 'getdata',
                        data: this.query_term,
                    }).then(res => {
                        let tableHead = [{
                                'label': '商品编码',
                                'property': 'combination_product_id',
                                'export': true
                            },
                            {
                                'label': '商品名称',
                                'property': 'combined_name',
                                'export': true
                            },
                            {
                                'label': '品牌',
                                'property': 'brand',
                                'export': true
                            },
                            {
                                'label': '销售状态',
                                'property': 'sales_status',
                                'export': true
                            },
                            {
                                'label': '线上日常价',
                                'property': 'front_end_price',
                                'export': true
                            },
                            {
                                'label': '折扣率',
                                'property': 'discount',
                                'export': true
                            },
                            {
                                'label': '私域售价',
                                'property': 'private_sphere_price',
                                'export': true
                            },
                            {
                                'label': '库存',
                                'property': 'inventory',
                                'export': true
                            },
                        ]
                        jsonToExcel(res, tableHead, '产品定价表')
                        this.autocomplete = true
                    })
                }
            },
            handleCurrentChange(currentPage) {
                if (!this.query_term.tabname) {
                    return
                }
                if (this.autocomplete) {
                    this.autocomplete = false
                    this.query_term['tabname'] = '订单助手'
                    this.query_term['currentPage'] = currentPage
                    axios({
                        method: "post",
                        url: 'getdata',
                        data: this.query_term,
                    }).then(res => {
                        this.dialogdata = res.data
                        this.total = res.total
                        this.autocomplete = true
                    })
                }
            },
            extractKeywords(string, keywords) {
                const keywordPattern = `(${keywords.join('|')})`;
                const regex = new RegExp(keywordPattern, 'gi');
                return string.match(regex) || [];
            },
            querySearchAsync(queryString, cb) {
                // 所有产品推广名
                if (this.autocomplete) {
                    queryString = queryString.replace(/\s+/g, '')
                    if (queryString == "" || queryString.length < 2) {
                        return
                    }
                    this.autocomplete = false
                    let lookup = {
                        tabname: '订单助手',
                        currentPage: 1,
                        search: {},
                        where: []
                    }
                    this.currentPage = 1
                    this.disabled = false
                    const regex = /[\u4E00-\u9FA5]/g;
                    if (regex.test(queryString)) {
                        for (let i = 0; i < this.speech.length; i++) {
                            let taps1 = this.extractKeywords(queryString, this.speech[i][2])
                            for (let ii = 0; ii < taps1.length; ii++) {
                                let strc = this.speech[i][1] == 'LIKE' ? `%${taps1[ii]}%` : taps1[ii];
                                if (!lookup.where.some((item, index) => index === i)) {
                                    lookup.where[i] = []
                                }
                                lookup.where[i].push([this.speech[i][0], this.speech[i][1], strc]);
                            }
                        }
                    } else {
                        lookup.where[0] = []
                        lookup.where[0].push(['combination_product_id', 'LIKE', "%" + queryString + "%"]);
                    }
                    this.query_term = lookup
                    axios({
                        method: "post",
                        url: 'getdata',
                        data: lookup,
                    }).then(res => {
                        this.dialogdata = res.data
                        this.total = res.total
                        this.autocomplete = true

                        // cb(res.data);
                    })
                }
            },
            handleClosed() {
                this.dialogVisible = false
            },
            dialoghandleSelect(row) {
                for (let i = 0; i < row.length; i++) {
                    this.handleSelect(row[i])
                }
            },
            getproduct(v = 0) {
                if (v) {
                    // 清空列表
                    this.cods = []
                    this.tags = []
                    this.product = []
                    return
                }
                this.autocomplete = false
                let lookup = {
                    tabname: '获取价格表',
                    search: {},
                    where: []
                }
                for (let i = 0; i < this.cods.length; i++) {
                    lookup.where.push(['combination_product_id', 'LIKE', "%" + this.cods[i] + "%"]);
                }
                axios({
                    method: "post",
                    url: 'getdata',
                    data: lookup,
                }).then(res => {
                    this.product = []
                    for (let i = 0; i < res.length; i++) {
                        // 过滤不包含选定商品的组合
                        let combination_product_id = res[i].combination_product_id.split('+')
                        let result = combination_product_id.every(element =>
                            this.cods.includes((element.split('*'))[0])
                        );
                        if (result) {
                            this.product.push(res[i]);
                        }
                    }
                    this.autocomplete = true
                })
            }
        }
    }
</script>

<style scoped>
    .el-row {
        margin-bottom: 5px;
    }

    .my-autocomplete li {
        line-height: normal;
        padding: 7px;
    }

    .my-autocomplete li .name {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .my-autocomplete li .addr {
        font-size: 12px;
        color: #b4b4b4;
    }

    .my-autocomplete li .highlighted .addr {
        color: #ddd;
    }

    .my-header {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .affix-container {}

    .infinite-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .infinite-list .infinite-list-item {
        margin-bottom: 8px;
        margin-top: 8px;
        /* margin: 0px; */
    }
</style>